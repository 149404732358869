import React from 'react';

import styles from './FooterWithLinks.module.css';

import maSavitriLogo from '../../assets/mashavitri logo.png';
import { Link } from 'react-router-dom';

const FooterWithLinks = () => {
  return (
    <footer className={`bg-sinduri ${styles.mantralekhanFooter}`}>
      <div className="container-fluid p-2">
        <div className="row">
          <div className="col-md-5 d-flex align-items-center m-3">
            <img
              src={maSavitriLogo}
              style={{ maxWidth: '100px', height: 'auto' }}
            />
            <h5>Maa Savitri Jyotish Anusandhan Kendra</h5>
          </div>
          <div className="col-md-3 m-3">
            <h5 className="fw-bold">Useful Links</h5>
            <Link to="/faq">Frequently Asked Questions</Link>
            <br />
            <a href="https://masavitrijyotish.com/about-us" target="_blank">
              About Us
            </a>
            <br />
            <a href="https://masavitrijyotish.com/contact-us" target="_blank">
              Contact Us
            </a>
          </div>
          <div className="col-md-3 m-3">
            <h5 className="fw-bold">Support Us</h5>
            <p className="m-0">
              To support us, you can make donation via UPI to{' '}
              <a href="upi://pay?pa=m.s.j.a.mum@okhdfcbank&cu=INR">
                m.s.j.a.mum@okhdfcbank
              </a>
            </p>
          </div>
        </div>
        <hr className="m-0" />
        <div className="row">
          <p className="text-center m-2">
            Developed & Maintained by{' '}
            <a
              className="fw-bold"
              href="https://www.flutebyte.com"
              target="_blank"
            >
              Flutebyte Technologies
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterWithLinks;
