import React, {useState} from 'react';
import './Login.css';
import sitaramBankLogo from '../../assets/sitaram-bank-logo.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Login = ({getUserData}) => {
  const navigate = useNavigate();
  const LoginWithGoogle = () => {
    localStorage.clear();
    window.open(`${process.env.REACT_APP_SERVER_URL}/auth/google`, '_self');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;
    // Do something with the form data
    localStorage.clear();
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/login`, { email, password })
      .then(response => {
        // Handle the response
        // console.log(response)
        if(response.data.statusCode === 200){
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 2300
          })
          // window.alert(response.data.message);
          setTimeout(() => {
            getUserData();
            navigate('/');
          }
          , 1100);
        }
      })
      .catch(error => {
        // Handle the error
        toast.error(error.response.data.data, {
          position: "top-center", 
          autoClose: 2500
        })
        // window.alert(error.response.data.data);
      });
  };

  return (
    <div className="login-page d-flex flex-column align-items-center">
      <ToastContainer />
      <img src={sitaramBankLogo} style={{ margin: 20, height: 60 }} alt="Sitaram Bank Logo" />
      <h2 style={{ textAlign: 'center' }} className='login-title'>Login</h2>
      <div className="form" style={{ textAlign: 'center', marginTop: '20px' }}>
        <form className="login-form" onSubmit={handleSubmit}>
          <input type="text" name="email" placeholder="Email" />
          <input type="password" name="password" placeholder="Password" />
          <button type="submit" className="login-button">Login</button>
          <p className="message">
            Not Registered? <NavLink to="/register" activeclassname="active" exact="true">Create an account</NavLink>
          </p>
        </form>
        <div className="line-container">
          <span className="line-text">or</span>
        </div>
        <button
          className="login-with-google-btn mt-4"
          onClick={LoginWithGoogle}
        >
          Sign In With Google
        </button>
      </div>
    </div>
  );
};

export default Login;
