import React from 'react';
import './FAQ.css';
import RightNavigation from '../../components/RightNavigation/RightNavigation';
import FooterWithLinks from '../../components/FooterWithLinks/FooterWithLinks';

const FAQ = () => {
  return (
    <div className="fav-main">
      <div className="faq-main-div editor">
        <div className="faq-child1-div ">
          <h3 className="faq-head">Shri Sita Ram Mantra Lekhan - FAQs</h3>

          <div>
            <span>What is Shri Sita Ram Mantra Lekhan?</span>
            <p>
              Shri Sita Ram Mantra Lekhan is the practice of writing the sacred
              names of Lord Rama and Goddess Sita. This spiritual exercise helps
              devotees connect deeply with the divine couple, embodying their
              virtues of love, devotion, and righteousness.
            </p>
          </div>

          <div>
            <span>What are the benefits of writing Shri Sita Ram mantra?</span>
            <p>
              <ul>
                <li>
                  <strong>Inner Peace and Calm:</strong> Writing the mantra can
                  calm the mind and bring a deep sense of inner peace.
                </li>
                <li>
                  <strong>Spiritual Growth:</strong> This practice fosters a
                  deeper connection with the divine, aiding in spiritual growth
                  and enlightenment.
                </li>
                <li>
                  <strong>Positive Energy:</strong> The mantra radiates positive
                  energy, dispelling negative thoughts and emotions.
                </li>
                <li>
                  <strong>Focus and Concentration:</strong> It enhances focus
                  and concentration, helping devotees to stay grounded and
                  mindful.
                </li>
              </ul>
            </p>
          </div>

          <div>
            <span>How should I start Shri Sita Ram Mantra Lekhan?</span>
            <p>
              <ul>
                <li>
                  <strong>Set Your Intention:</strong> Begin with a clear and
                  pure intention to connect with the divine energies of Sita and
                  Ram.
                </li>
                <li>
                  <strong>Find a Quiet Space:</strong> Choose a calm and quiet
                  place where you can focus without distractions.
                </li>
                <li>
                  <strong>Use a Dedicated Notebook:</strong> Write the Sita Ram
                  mantra in a dedicated notebook, preferably in a clean and
                  sacred space.
                </li>
                <li>
                  <strong>Consistency is Key:</strong> Regular practice, whether
                  daily or weekly, amplifies the benefits and deepens your
                  connection to the divine.
                </li>
              </ul>
            </p>
          </div>

          <div>
            <span>Can anyone practice Shri Sita Ram Mantra Lekhan?</span>
            <p>
              Yes, anyone with a sincere heart and devotion to Lord Rama and
              Goddess Sita can practice mantra lekhan. It is a simple yet
              profound way to engage with the divine and imbibe their virtues in
              your daily life.
            </p>
          </div>

          <div>
            <span>
              Do I need any special materials for Shri Sita Ram Mantra Lekhan?
            </span>
            <p>
              You can write Sitaram Mantra in many ways to write Sitaram Mantra,
              but here we advise you to write Sitaram Mantra online. To write
              Sitaram Mantra online, it is necessary to have an Android mobile
              and internet is also necessary. If you write Sitaram Mantra from
              Android mobile, it gets collected on the cloud and you get its
              merit by dedicating it to Shri Sitaram every year through Yagya
              Havan.{' '}
              <p className="sinduri" style={{ display: 'inline' }}>
                This opportunity to get merit is given every year by the
                organization on the day of Shravan month Shukla Paksha Nag
                Panchami, Kalsarp Dosh and Grah Shanti Yagna is dedicated to
                Sitaram through Aahuti on the basis of time of Yagya and
                Purnahut, due to which the person writing the mantra starts
                getting the fruit.
              </p>{' '}
              We request the great people writing mantras that if you are
              involved in writing mantras, then join the Yagya which we do on
              the day of Nag Panchami by the organization, you can also get the
              merit of writing Sitaram Mantra by performing Yagya. The second
              basis of writing is that you can also write using a copy pen. To
              write using a pen, it is necessary to have a notebook and a pen.
            </p>
          </div>

          <div>
            <span>How often should I write the Shri Sita Ram mantra?</span>
            <p>
              There is no fixed rule on frequency. You can write the mantra
              daily, weekly, or as often as you feel guided. Consistency in
              practice can help deepen your spiritual experience.
            </p>
          </div>

          <div>
            <span>What should I do if I miss a day of mantra writing?</span>
            <p>
              It's important to maintain a regular practice, but if you miss a
              day, don't worry. Simply continue the next day. The intention and
              sincerity behind your practice matter more than perfection.
            </p>
          </div>

          <div>
            <span>Can I chant the mantra while writing it?</span>
            <p>
              Yes, chanting the mantra softly while writing it can enhance the
              spiritual experience and help you stay focused and connected to
              the divine energies of Sita and Ram.
            </p>
          </div>

          <div>
            <span>
              Is there a specific number of times I should write the mantra?
            </span>
            <p>
              There is no specific number. Write the mantra as many times as you
              feel comfortable. Some devotees set a personal goal, such as
              writing the mantra 108 times, but it's entirely up to you.
            </p>
          </div>
        </div>

        <div className="faq-child2-div ">
          <RightNavigation />
        </div>
      </div>
      <FooterWithLinks />
    </div>
  );
};

export default FAQ;
