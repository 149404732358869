import React from 'react';
import './RightNavigation.css';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
const RightNavigation = () => {
  return (
    <div className="right-nav-main">
      <p>Navigation</p>
      {/* <Link to="/ShriSitaRam">
        <div>
          <RightOutlined /> Shri Sita Ram <br />
        </div>
      </Link> */}
      <a href="https://masavitrijyotish.com/about-us" target="_blank">
        <div>
          <RightOutlined /> About Us <br />
        </div>
      </a>
      <a href="https://masavitrijyotish.com/contact-us" target="_blank">
        <div>
          <RightOutlined /> Contact Us <br />
        </div>
      </a>
      <Link to="/faq">
        <div>
          <RightOutlined /> Frequently Asked Questions <br />
        </div>
      </Link>
      <Link to="/testimonial">
        <div>
          <RightOutlined /> Testimonials <br />
        </div>
      </Link>
      <Link to="/topusers">
        <div>
          <RightOutlined /> Top Users <br />
        </div>
      </Link>
    </div>
  );
};

export default RightNavigation;
