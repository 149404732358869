import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../utility/AuthContext';

const ProtectedRoute = ({ children, authenticationRequired = true }) => {
  const { isAuthenticated, redirectPath } = useAuth();
  // console.log('entered protected route');
  // console.log(redirectPath, isAuthenticated);

  if(authenticationRequired && (localStorage.getItem('userId') && localStorage.getItem('userDetails'))) {
    return children;
  }else if (authenticationRequired && !(localStorage.getItem('userId') && localStorage.getItem('userDetails'))) {
    // User is not authenticated but trying to access a protected route
    localStorage.clear();
    return <Navigate to="/login" replace />;
  } else if (!authenticationRequired && (localStorage.getItem('userId') && localStorage.getItem('userDetails'))) {
    // User is authenticated but trying to access login or register page
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default ProtectedRoute;