import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [redirectPath, setRedirectPath] = useState('/');
  const location = useLocation();
  const navigate = useNavigate();

  const checkAuthStatus = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/login/success`, {
        withCredentials: true,
      });
      if (response.data.data) {
        setIsAuthenticated(true);
        localStorage.setItem('userId', response.data.data.id);
        localStorage.setItem('userDetails', JSON.stringify(response.data.data));
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    checkAuthStatus();
  }, []);

  useEffect(() => {
    // Update redirectPath when location changes, but not for login or register routes
    if (!location.pathname.includes('/login') && !location.pathname.includes('/register')) {
      setRedirectPath(location.pathname);
    }
  }, [location]);

  const login = () => {
    console.log('Login context');
    setIsAuthenticated(true);
    navigate(redirectPath);
  };

  const logout = () => {
    console.log('Logout context');
    setIsAuthenticated(false);
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, login, logout, redirectPath }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);