import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Navbar from '../../components/Navbar/Navbar';
// import image1 from '../../assets/Bhagwanji.png';
import SitaramImage from '../../assets/sitaram.png';
import sitaramSound from '../../assets/sitaram_sound.mp3';

import './Mantrapage.css';
import FooterWithLinks from '../../components/FooterWithLinks/FooterWithLinks';

function Mantrapage() {
  const [word, setWord] = useState('');
  const [lines, setLines] = useState(Array(55).fill(''));
  const [wordCount, setWordCount] = useState(0);
  const [autoEnter, setAutoEnter] = useState(false);
  const [userData, setUserData] = useState(null);
  const apiUrl = process.env.REACT_APP_SERVER_URL;
  const wordPattern = 'sitaram';
  useEffect(() => {
    const getUserData = async () => {
      try {
        axios.defaults.withCredentials = true;
        let response = await axios(`${apiUrl}/api/v1/login/success`, {
          method: 'GET',
          withCredentials: true,
        });

        setUserData(response.data.data);
        localStorage.setItem('userId', response.data.data.id);
        localStorage.setItem('userDetails', JSON.stringify(response.data.data));
      } catch (error) {
        console.log('Error Fetching User Data.', error);
        window.location.href = '/login';
      }
    };
    getUserData();
  }, []);
  const audioRef = useRef(null);
  const addCount = async () => {
    // const accessToken = Cookies.get('accessToken');
    // console.log(accessToken);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/mantralekhan`,
        { id: localStorage.getItem('userId') },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      // console.log(response.data);
    } catch (error) {
      console.error('There was a problem with your Axios request:', error);
    }
  };
  const handleInputChange = async (e) => {
    const input = e.target.value.slice(0, 12);
    const newWord = input
      .toLowerCase()
      .split('')
      .filter((char, index) => index < 12 && char === wordPattern[index])
      .join('');
    setWord(newWord);
    if (autoEnter && newWord === wordPattern) {
      handleMantraInputSuccess();
    }
  };

  const handleEnterPress = async (e) => {
    if (!autoEnter && word === wordPattern) {
      handleMantraInputSuccess();
      e.preventDefault(); // Prevent default action of Enter key
    }
  };

  const addWordToLines = () => {
    const newLines = [...lines];
    const lineIndex = newLines.findIndex((line) => line === '');
    if (lineIndex !== -1) {
      newLines[lineIndex] = wordPattern;
      setLines(newLines);
      setWord('');
      setWordCount(wordCount + 1);
      if (lineIndex === 54) {
        setLines(Array(55).fill(''));
      }
    } else {
      setWord('');
    }
  };

  const handleMantraInputSuccess = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
    addCount();
    addWordToLines();
  };

  return (
    <div className="mantra-main">
      <div className="flex flex-col items-center">
        <audio ref={audioRef} src={sitaramSound} />
        <img alt="Sitaram" src={SitaramImage} style={{ height: 300 }} />
        <div className="mt-3">
          <input
            type="text"
            value={word}
            onChange={handleInputChange}
            onPaste={(e) => e.preventDefault()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleEnterPress(e);
            }}
            placeholder="sitaram"
            className="mantrainput"
          />
          <p className="text-center">Total {wordCount} and counting...</p>
        </div>
        <label>
          <input
            type="checkbox"
            checked={autoEnter}
            onChange={() => setAutoEnter(!autoEnter)}
            className="enterCheckBox"
          />
          &nbsp; Skip Enter Key
        </label>
      </div>
      <div className="lines">
        {lines.map((line, index) => (
          <div key={index} className="line">
            {line}
          </div>
        ))}
      </div>
      <FooterWithLinks />
    </div>
  );
}

export default Mantrapage;
